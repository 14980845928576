
import { defineComponent } from "vue";
import {getPackagePointsList, usePackagePoints}  from "@/api/ovi_api";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ECP_EDP_DashboardNavigator from "@/components/cards/ECP_EDP_DashboardNavigator.vue"


export default defineComponent({
  name: "ovicoveragetransferlist", 
  methods: {
    async loadPackagePointsList(){ 
      this.loading = true;
      const response = await getPackagePointsList();
       this.list = response.data.pageList; 
       this.isShowPackagePointsWarning = response.data.isShowPackagePointsWarning;
       this.loading = false;
    },
    async usePackagePoints(recid){
      this.loading = true;
      const response = await usePackagePoints(recid);
      this.isSending = true;
      if (response.data.result == "SUCCESS") {
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }); 
        window.location.reload();
        return; 
      } else if(response.data.result == "FAILED"){
        this.isSending = false;
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        }); 
        return; 
      }
      this.loading = false;
    },
    async getPT(){
       this.pt = String(localStorage.getItem("pt"));
    }
  },
  mounted(){
      this.loadPackagePointsList();
     this.getPT();
  },
  data(){
      return { 
          list : [],
          allowClose: true,
          inputActivationDetail: false,
          spcode: "",
          sealproencodekey: "",
          isSending: false,
          isShowPackagePointsWarning: false,
          loading: true,
          pt : "" 
      }
  }, 
  components: {
    ECP_EDP_DashboardNavigator
  },
  props: {
    widgetClasses: String
  }
});
