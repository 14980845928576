
import { defineComponent, computed } from "vue";
import {isPGIQualified, isPGITop20Qualified, vGetCurrentRank} from "@/api/dashboard"

export default defineComponent({
  name: "ecpedpdashboardnavigator",
  components: {},
  props: {
  },
  data(){
    return{
      isPGIQualified:false,
      isPGITop20Qualified:false,
      currentRank: "",
    }
  },
  methods:{
    async checkIfPGIQualified(){
      const response = await isPGIQualified();
      this.isPGIQualified = response.data.isPGIQualified;
    },
    async checkIfPGITop20Qualified(){
      const response = await isPGITop20Qualified();
      this.isPGITop20Qualified = response.data.isPGITop20Qualified;
    },
    async getCurrentRank(){
      const response = await vGetCurrentRank();
      this.currentRank = response.data.currentRank;
    }
  },
  mounted(){
    this.checkIfPGIQualified();
    this.checkIfPGITop20Qualified();
    this.getCurrentRank();
  }
});
