import request from '@/utils/request'

//Load Coverage Transfer List
export function loadCoverageTransferList() {
  return request({ url: 'coveragetransferlist', method: 'post', data: {'x': 'x'} })
} 


//Load Coverage Transfer List
export function getOVICoverageInfo() {
  return request({ url: 'vovicoverageinfo', method: 'post', data: {'x': 'x'} })
} 


//Load Coverage Transfer List
export function getPackagePointsList() {
  return request({ url: 'packagepointslist', method: 'post', data: {'x': 'x'} })
} 


//Load Coverage Transfer List
export function usePackagePoints(recid) {
  return request({ url: 'usepackagepoints', method: 'post', data: {'recid': recid} })
}

export function coverage_send_activation(data) {
  return request({ url: 'coverage_send_activation', method: 'post', data: data })
}


export function getRegcodesCoverages(data) {
  return request({ url: 'getregcodescoverage', method: 'post', data: data })
}

export function getImmediateCoverages(data) {
  return request({ url: 'getimmediatecoverages', method: 'post', data: data })
}

export function getDreamLyfList() {
  return request({ url: 'getdreamlyflist', method: 'get'})
} 


export function getrenewalavailablecoverages(data) {
  return request({ url: 'getrenewalavailablecoverages', method: 'post', data: data })
}



