import request from '@/utils/request'


export function getDashboardData() {
  return request({ url: 'vgetDashboardData', method: 'get'})
} 

export function getMaxCoverage() {
  return request({ url: 'vgetMaxCoverage', method: 'get'})
} 


export function getDashboardECPWelcomeData() {
  return request({ url: 'vdashboardecp_welcome', method: 'get'})
} 

export function getAccountInfo() {
  return request({ url: 'vgetaccountinfo', method: 'get'})
} 

export function getMoreAccountInfo() {
  return request({ url: 'vseemoreaccountinfo', method: 'get'})
} 


export function getPrimeMonitoring() {
  return request({ url: 'vgetprimemonitoring', method: 'get'})
} 


export function isPGIQualified() {
  return request({ url: 'vispgiqualified', method: 'get'})
} 


export function isPGITop20Qualified() {
  return request({ url: 'vispgitop20qualified', method: 'get'})
} 

export function getBalanceDetails() {
  return request({ url: 'vgetBalanceDetails', method: 'get'})
} 


export function vGetCurrentRank() {
  return request({ url: 'vgetcurrentrank', method: 'get'})
} 


export function vGetPackageType() {
  return request({ url: 'vgetpackagetype', method: 'get'})
} 


export function getUserCountryCode() {
  return request({ url: 'vgetuserccode', method: 'get'})
} 


export function getPrimeOneYear() {
  return request({ url: 'vgetmonthlyprimerank', method: 'get'})
} 


export function getEDPLANPairCount() {
  return request({ url: 'vgetedplanpaircount', method: 'get'})
} 



export function getBankAccountNo() {
  return request({ url: 'vgetbankdtls', method: 'get'})
} 